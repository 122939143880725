<template>
  <v-dialog v-model="deleteDialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn rounded depressed color="red" v-on.prevent="on" v-bind="attrs" class="white--text">{{ $t('delete')
      }}</v-btn>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center">
        <h2> {{ $t('are you sure delete') }} {{ template.name }} ؟</h2>
      </v-card-title>
      <v-card-actions class="justify-center">
        <v-btn color="green" class="white--text" depressed @click="deleteRecord"
          :loading="deleteBtnLoading">{{ $t('delete') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      deleteBtnLoading: false,
      deleteDialog: false,
    };
  },
  props: {
    template: Object,
  },
  methods: {
    async deleteRecord() {
      try {
        this.deleteBtnLoading = true;
        await axios.delete(`/inventories/invoice-template/${this.template.id}`);
        this.$emit("template-deleted");
        this.deleteDialog = false;
        this.$Notifications(
              this.$t('delete success'),
          { timeout: 2000, rtl: true },
          "success"
        );
      } catch (err) {
      } finally {
        this.deleteBtnLoading = false;
      }
    },
  },
};
</script>

<style>

</style>