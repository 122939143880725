<template>
  <div>
    <v-col md="12">
        <v-row>
          <v-col md="3">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="green" depressed class="white--text mt-5 mr-5" rounded dark v-bind="attrs" v-on="on">
                  {{ $t('add template') }} <v-icon>mdi-plus</v-icon> </v-btn>
              </template>
              <v-list>
                <v-list-item @click="itemClicked(item)" style="cursor: pointer" v-for="item in items" :key="item.id">
                  <v-list-item-title>{{
                    invoiceTypeFormatter(item)
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col md="3" class="py-7">
            <AutoCompleteField :label="$t('Template Name')" v-model="filters.id" clearable @input="fetchTemplates"
                      endPoint="/inventories/invoice-template/index" />
          </v-col>
          <v-col md="3" class="py-7">
            <AutoCompleteField :label="$t('Invoice Name')" v-model="filters.invoice_type_id" clearable  @input="fetchTemplates"
                      endPoint="/inventories/invoice-types/index" />
          </v-col>
          <v-col md="1" class="py-10">
                <v-icon :dark="$store.state.isDarkMode" large color="gray" @click="() => {
                    filters = {}
                    fetchTemplates();
                } ">mdi-close</v-icon>
          </v-col>
          <v-col md="1" class="py-10">
              <v-icon :dark="$store.state.isDarkMode" large color="gray" @click="fetchTemplates"> mdi-magnify </v-icon>
          </v-col>
        </v-row>
      </v-col>
    <div class="table__spinnerWrapper" v-if="loading">
      <app-spinner></app-spinner>
    </div>
    <v-row v-else>
     
      <v-col md="12">
        <v-row>
        <template v-for="template in templates">
        <v-col md="4" :key="template.id">
          <v-card elevation="3" class="mt-5 mx-5" width="320">
            <v-card-title class="justify-center">
              <h2>{{ template.name }}</h2>
            </v-card-title>
            <v-card-actions class="justify-space-between">
              <v-btn rounded depressed color="info" class="white--text" @click.prevent="
      $router.push(`editInvoiceTemplate/${template.id}`)
      ">{{ $t('edit') }}</v-btn>
              <v-btn color="green" class="white--text" rounded @click="goToEntryAdd(template.id)">{{ $t('use')
                }}</v-btn>
              <app-delete-template :template="template" @template-deleted="fetchTemplates"></app-delete-template>
            </v-card-actions>
          </v-card>
        </v-col>
      </template>
    </v-row>
    </v-col>
      <v-col md="12" class="justify-center">
            
            <pagination v-if="(!loading & (paginated))" :disable-pagination="!paginated" :totalPages="total"
                :page="page" @PaginationValue="applyPagination($event)"/>
        
      </v-col>
        
         
          <v-row>
            <v-col md="3"></v-col>
              <v-col md="3" >
                  <v-switch :dark="$store.state.isDarkMode" @change="goToAll" v-model="is_all" color="green" :label="$t('Show All')"></v-switch>
              </v-col>
          </v-row>
          <v-row></v-row>
      
    

    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import deleteTemplate from "./deleteTemplate.vue";
import AutoCompleteField from "../../../components/core/AutoCompleteField.vue";
import pagination from "../../../../../components/base/pagination.vue";

export default {
  components: {
    appDeleteTemplate: deleteTemplate,
    AutoCompleteField,
    pagination,
  },
  data() {
    return {
      items: [],
      loading: false,
      templates: [],
      is_all: false,
      paginated: true,
      total: 1,
      page: 1,
      filters: {
        invoice_type_id: null,
        id: null,
      },
    };
  },
  methods: {
    applyPagination(paginationValue) {
      this.page = paginationValue;
      this.fetchTemplates();
    },
    goToAll() {
      if (this.is_all) {
        this.paginated = false;
      } else { this.paginated = true; }
      this.fetchTemplates();
    },
    invoiceTypeFormatter(invoiceType) {
      const invoiceTemp = [
        {
          label: "فاتورة مشتريات",
          value: "PURCHASES",
        },
        {
          label: "فاتورة مبيعات",
          value: "SALES",
        },
        {
          label: "فاتورة مرتجع المشتريات",
          value: "PURCHASE_REFUNDS",
        },
        {
          label: "فاتورة مرتجع المبيعات",
          value: "SALES_REFUNDS",
        },
        {
          label: "فاتورة مناقلة",
          value: "TRANSPORTATION",
        },
      ].find((el) => el.value == invoiceType?.type);
      let str = "";
      if (invoiceTemp) {
        str = ` ${invoiceType.name}`;
      }
      return str;
    },
    itemClicked(v) {

      this.$router.push(`newinvoiceTemplate/${v.id}`);

    },
    invoiceTypeLabelFormatter(type) {
      return [
        {
          label: "فاتورة مشتريات",
          value: "PURCHASES",
        },
        {
          label: "فاتورة مبيعات",
          value: "SALES",
        },
        {
          label: "فاتورة مرتجع المشتريات",
          value: "PURCHASE_REFUNDS",
        },
        {
          label: "فاتورة مرتجع المبيعات",
          value: "SALES_REFUNDS",
        },
        {
          label: "فاتورة مناقلة",
          value: "TRANSPORTATION",
        },
      ].find((el) => el.value == type).label;
    },
    async getInvoiceTypes() {
      const res = await axios.get("/inventories/invoice-types/index");
      this.items = res.data.data.map((el) => ({
        ...el,
        invoiceArLabel: this.invoiceTypeLabelFormatter(el.type),
      }));
      console.log(this.items);
    },
    goToEntryAdd(id) {
      if (this.returnAbility("invoice:store")) {
        this.$router.push(`/createInvoiceFromTemplate/${id}`);
      }
    },
    async fetchTemplates() {
      try {
        //if (!this.returnAbility("entry-template:index")) return;
        this.loading = true;
        const filterTemp = {};
        Object.keys(this.filters).forEach((key) => {
          if (this.filters[key]) filterTemp[key] = this.filters[key];
        });
        const templates = await axios.get("/inventories/invoice-template/index", {
                    params: {
                        size: 9,
                        page: this.page,
                        paginated: this.paginated,
                        ...filterTemp,
                    },
                });
        this.templates = templates.data.data;
        this.total = templates.data.meta.total;
        this.page = templates.data.meta.current_page;
      } catch (err) {
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.fetchTemplates();
    this.getInvoiceTypes();
  },
};
</script>

<style>
.accounting-templates__spinner-wrapper {
  width: 100%;
  height: 89vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>